// extracted by mini-css-extract-plugin
export var bannerBtn = "Discover2-module--bannerBtn--ecc42";
export var btn_white1_border = "Discover2-module--btn_white1_border--48c15";
export var card = "Discover2-module--card--2495e";
export var cardWrapper = "Discover2-module--cardWrapper--2616b";
export var choseImg = "Discover2-module--choseImg--64428";
export var description = "Discover2-module--description--5003b";
export var heading = "Discover2-module--heading--d9c3c";
export var ignite = "Discover2-module--ignite--5bce5";
export var ignite2 = "Discover2-module--ignite2--0e12d";
export var numbr = "Discover2-module--numbr--c27f1";
export var subservice = "Discover2-module--subservice--a5129";
export var teamBtn = "Discover2-module--teamBtn--b4a38";
export var title = "Discover2-module--title--9dfd3";
export var title2 = "Discover2-module--title2--0928c";