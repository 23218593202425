import React from "react"
import { Link } from "gatsby"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Tab from "react-bootstrap/Tab"
import "./Discover.scss"
import * as styles from "./Discover.module.scss"

const Discover = ({ strapiData }) => {
  return (
    <div className="softTabingApp">
      <div>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <div className="Blocks">
            <Tab.Content className="contentBlocks3"></Tab.Content>
          </div>
          <div className="Blocks2">
            <Tab.Content className="contentBlocks2"></Tab.Content>
          </div>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="Project Analysis"
          >
            <div className={styles.cards}>
              <div className={styles.cardsContent}>
                <div>
                  <Nav variant="pills" className={` discover`}>
                    {strapiData?.cards &&
                      strapiData?.cards?.map((e, i) => (
                        <Nav.Item key={i}>
                          <Nav.Link eventKey={`${e?.title}`}>
                            <div className={styles.tabData}>
                              <p>Step 0{i + 1}</p>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                  </Nav>
                </div>
                <div className={styles.tabs}>
                  <Tab.Content className="contentBlock">
                    {strapiData?.cards?.map((e, i) => (
                      <Tab.Pane
                        className="tabsss"
                        eventKey={`${e?.title}`}
                        key={i}
                      >
                        <div className={styles.boxContent}>
                          <span>0{i + 1}</span>
                          <img
                            decoding="async"
                            loading="lazy"
                            src={
                              e?.image1 && e?.image1[0]?.localFile?.publicURL
                            }
                            alt={`${e.title}`}
                          />
                          <div>
                            <h3>{e?.title}</h3>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: e?.description?.description,
                              }}
                            />
                          </div>
                        </div>
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </div>
              </div>
            </div>
          </Tab.Container>
          <div className={styles.bannerBtn2}>
            {strapiData?.buttons[0] && (
              <Link
                to={strapiData?.buttons[0]?.url}
                className={styles.btn_white1_border}
              >
                {strapiData?.buttons[0]?.title}
              </Link>
            )}
          </div>
        </Container>
      </div>
    </div>
  )
}
export default Discover
