// extracted by mini-css-extract-plugin
export var bannerBtn = "Unlock-module--bannerBtn--80a68";
export var bannerBtn2 = "Unlock-module--bannerBtn2--7f53b";
export var btn_white1_border = "Unlock-module--btn_white1_border--2d2c7";
export var cards = "Unlock-module--cards--ef610";
export var description = "Unlock-module--description--bda0e";
export var hire = "Unlock-module--hire--77913";
export var hireCyber = "Unlock-module--hireCyber--63e1a";
export var hireImg = "Unlock-module--hireImg--4cf83";
export var hr = "Unlock-module--hr--8ef21";
export var subTitle = "Unlock-module--subTitle--08dbd";
export var talent = "Unlock-module--talent--60efa";
export var text = "Unlock-module--text--6debd";
export var tick = "Unlock-module--tick--e7ec9";