// extracted by mini-css-extract-plugin
export var Group = "WhySettle-module--Group--693dd";
export var SliderWidth = "WhySettle-module--SliderWidth--377c3";
export var arrowImg = "WhySettle-module--arrowImg--5a558";
export var card = "WhySettle-module--card--654cf";
export var cardWrapper = "WhySettle-module--cardWrapper--00178";
export var cards = "WhySettle-module--cards--25634";
export var cir = "WhySettle-module--cir--def90";
export var description = "WhySettle-module--description--c9022";
export var dots1 = "WhySettle-module--dots1--0f964";
export var heading = "WhySettle-module--heading--645ec";
export var headingPremium = "WhySettle-module--headingPremium--152c7";
export var premiumImg = "WhySettle-module--premiumImg--22202";
export var providingImg = "WhySettle-module--providingImg--b83f0";
export var teamButton = "WhySettle-module--teamButton--6da25";
export var teamWork = "WhySettle-module--teamWork--7c3b9";
export var trailBg = "WhySettle-module--trailBg--c8f07";