// extracted by mini-css-extract-plugin
export var bannerBtn2 = "Discover-module--bannerBtn2--b68f9";
export var boxContent = "Discover-module--boxContent--efbd2";
export var btn_white1_border = "Discover-module--btn_white1_border--6c7fe";
export var bulitLine = "Discover-module--bulitLine--0bbd8";
export var cards = "Discover-module--cards--3ad4a";
export var cardsContent = "Discover-module--cardsContent--60f1f";
export var cardsZ = "Discover-module--cardsZ--b09b7";
export var description = "Discover-module--description--5cda6";
export var estabBtn = "Discover-module--estabBtn--de35e";
export var heading = "Discover-module--heading--2bd73";
export var navItem = "Discover-module--nav-item--e6fc6";
export var navbarBlock = "Discover-module--navbarBlock--f85ff";
export var softTabingApp = "Discover-module--softTabingApp--a5580";
export var tabData = "Discover-module--tabData--19d10";
export var tabbingCards = "Discover-module--tabbingCards--6e944";
export var tabs = "Discover-module--tabs--1d031";