// extracted by mini-css-extract-plugin
export var Frame = "Superchange-module--Frame--a29ea";
export var Rec = "Superchange-module--Rec--3f15b";
export var advance = "Superchange-module--advance--05faf";
export var cir = "Superchange-module--cir--30301";
export var dots = "Superchange-module--dots--b687d";
export var for1 = "Superchange-module--for1--c578b";
export var heading = "Superchange-module--heading--10158";
export var iconssCard = "Superchange-module--iconssCard--bd3c5";
export var techBg = "Superchange-module--techBg--50123";
export var techDot = "Superchange-module--techDot--d7652";
export var techIcon = "Superchange-module--techIcon--a3cec";
export var techImg = "Superchange-module--techImg--80e78";
export var technologyIcon = "Superchange-module--technologyIcon--5e363";