// extracted by mini-css-extract-plugin
export var clientDots = "Testimonials-module--clientDots--669e2";
export var clientDots2 = "Testimonials-module--clientDots2--2bb4f";
export var content = "Testimonials-module--content--d293a";
export var dots3 = "Testimonials-module--dots3--b4394";
export var hireArrow = "Testimonials-module--hireArrow--d8e13";
export var iconContainer = "Testimonials-module--iconContainer--ae922";
export var iconContainerLeft = "Testimonials-module--iconContainerLeft--3e162";
export var imgBtn = "Testimonials-module--imgBtn--2540e";
export var person = "Testimonials-module--person--9fb65";
export var personBg = "Testimonials-module--personBg--bd7b5";
export var portfolioArrowIcon = "Testimonials-module--portfolioArrowIcon--68b16";
export var portfolioArrowIconCover = "Testimonials-module--portfolioArrowIconCover--be1fd";
export var portfolioArrowRightIconCover = "Testimonials-module--portfolioArrowRightIconCover--ffefc";
export var row = "Testimonials-module--row--aa0f4";
export var section = "Testimonials-module--section--a50a7";
export var staffRec = "Testimonials-module--staffRec--b1b9a";
export var testName = "Testimonials-module--testName--1a84d";
export var testimonials = "Testimonials-module--testimonials--bd7e0";
export var testimonialsContent = "Testimonials-module--testimonialsContent--93491";
export var vector2 = "Testimonials-module--vector2--b9a0e";