import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import { Link } from "gatsby"
import Row from "react-bootstrap/Row"
import * as styles from "./Discover2.module.scss"

const Discover = ({ strapiData }) => {
  return (
    <div className={`${styles.subservice}`}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <Row className={`gap-30 ${styles.cardWrapper}`}>
          {strapiData?.cards?.map((e, i) => (
            <Col xl={4} md={6} xs={12} key={i} className="gap-30">
              <div className={styles.card}>
                <img
                  src={e?.image1[2]?.localFile?.publicURL}
                  decoding="async"
                  loading="lazy"
                  alt={e?.image1[0]?.alternativeText}
                  className={styles.ignite2}
                />
                <div className={styles.numbr}>
                  <p
                    className={styles.title2}
                    dangerouslySetInnerHTML={{
                      __html: e?.subTitle,
                    }}
                  />
                  <h3 className={styles.title}>{e?.title}</h3>
                </div>

                <p
                  dangerouslySetInnerHTML={{
                    __html: e?.description?.description,
                  }}
                />
              </div>
            </Col>
          ))}
          <div className={`${styles.bannerBtn}`}>
            {strapiData?.buttons[0] && (
              <Link
                to={strapiData?.buttons[0]?.url}
                className={styles.btn_white1_border}
              >
                {strapiData?.buttons[0]?.title}
              </Link>
            )}
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default Discover
