// extracted by mini-css-extract-plugin
export var Group = "CreatePowerful-module--Group--66e88";
export var Vector = "CreatePowerful-module--Vector--55730";
export var card = "CreatePowerful-module--card--37709";
export var cardWrapper = "CreatePowerful-module--cardWrapper--c561c";
export var choseImg = "CreatePowerful-module--choseImg--5ecf0";
export var cir = "CreatePowerful-module--cir--bc786";
export var description = "CreatePowerful-module--description--4aff1";
export var dots1 = "CreatePowerful-module--dots1--b92af";
export var dots2 = "CreatePowerful-module--dots2--6c7f1";
export var dots4 = "CreatePowerful-module--dots4--af415";
export var dotscard = "CreatePowerful-module--dotscard--157d1";
export var heading = "CreatePowerful-module--heading--17dda";
export var react = "CreatePowerful-module--react--666d0";
export var subservice = "CreatePowerful-module--subservice--92099";
export var teamBtn = "CreatePowerful-module--teamBtn--94bb3";